import isEqual from 'lodash/isEqual';

import convertDietAddonToGtmItem from './convertDietAddonToGtmItem';
import convertDietToGtmItem from './convertDietToGtmItem';
import getBasketItemsDiff from './getBasketItemsDiff';

const mapAddonsIdsForDiffComparison = dietItemPresentBefore =>
  dietItemPresentBefore.addons.map(addon => ({
    ...addon,
    id: addon.addon.id,
  }));

const getGtmDietItemsWithDietAddons = ({
  brandName,
  prevDietItems,
  currentDietItems,
  prevBasketDiscountCode,
  currentBasketDiscountCode,
  withDietDiff,
}) => {
  const gtmRemovedDietItemsWithDietAddons = [];
  const gtmAddedDietItemsWithDietAddons = [];

  const {
    itemsRemoved: dietItemsRemoved,
    itemsAdded: dietItemsAdded,
    prevItemsPresentBefore: prevDietItemsPresentBefore,
    currentItemsPresentBefore: currentDietItemsPresentBefore,
  } = getBasketItemsDiff(prevDietItems, currentDietItems);

  dietItemsRemoved.forEach(dietItem => {
    gtmRemovedDietItemsWithDietAddons.push(
      convertDietToGtmItem({
        brandName,
        basketDiscountCode: prevBasketDiscountCode,
        dietItem,
      })
    );

    dietItem.addons.forEach(addon => {
      gtmRemovedDietItemsWithDietAddons.push(
        convertDietAddonToGtmItem({
          brandName,
          dietDaysLength: dietItem.days.length,
          dietAddon: addon,
        })
      );
    });
  });

  dietItemsAdded.forEach(dietItem => {
    gtmAddedDietItemsWithDietAddons.push(
      convertDietToGtmItem({
        brandName,
        basketDiscountCode: currentBasketDiscountCode,
        dietItem,
      })
    );

    dietItem.addons.forEach(addon => {
      gtmAddedDietItemsWithDietAddons.push(
        convertDietAddonToGtmItem({
          brandName,
          dietDaysLength: dietItem.days.length,
          dietAddon: addon,
        })
      );
    });
  });

  prevDietItemsPresentBefore.forEach(prevDietItemPresentBefore => {
    const currentDietItemPresentBefore = currentDietItemsPresentBefore.find(
      currentDietItem => currentDietItem.id === prevDietItemPresentBefore.id
    );

    if (
      withDietDiff &&
      !isEqual(prevDietItemPresentBefore, currentDietItemPresentBefore)
    ) {
      gtmRemovedDietItemsWithDietAddons.push(
        convertDietToGtmItem({
          brandName,
          basketDiscountCode: prevBasketDiscountCode,
          dietItem: prevDietItemPresentBefore,
        })
      );
      gtmAddedDietItemsWithDietAddons.push(
        convertDietToGtmItem({
          brandName,
          basketDiscountCode: currentBasketDiscountCode,
          dietItem: currentDietItemPresentBefore,
        })
      );
    }

    const {
      itemsRemoved: dietItemAddonsRemoved,
      itemsAdded: dietItemAddonsAdded,
      prevItemsPresentBefore: prevDietItemAddonsPresentBefore,
      currentItemsPresentBefore: currentDietItemAddonsPresentBefore,
    } = getBasketItemsDiff(
      mapAddonsIdsForDiffComparison(prevDietItemPresentBefore),
      mapAddonsIdsForDiffComparison(currentDietItemPresentBefore)
    );

    gtmRemovedDietItemsWithDietAddons.push(
      ...dietItemAddonsRemoved.map(addon =>
        convertDietAddonToGtmItem({
          brandName,
          dietDaysLength: prevDietItemPresentBefore.days.length,
          dietAddon: addon,
        })
      )
    );
    gtmAddedDietItemsWithDietAddons.push(
      ...dietItemAddonsAdded.map(addon =>
        convertDietAddonToGtmItem({
          brandName,
          dietDaysLength: currentDietItemPresentBefore.days.length,
          dietAddon: addon,
        })
      )
    );

    prevDietItemAddonsPresentBefore.forEach(prevAddon => {
      currentDietItemAddonsPresentBefore.forEach(currentAddon => {
        if (currentAddon.addon.id === prevAddon.addon.id) {
          if (currentAddon.type !== prevAddon.type) {
            gtmRemovedDietItemsWithDietAddons.push(
              convertDietAddonToGtmItem({
                brandName,
                dietDaysLength: prevDietItemPresentBefore.days.length,
                dietAddon: prevAddon,
              })
            );
            gtmAddedDietItemsWithDietAddons.push(
              convertDietAddonToGtmItem({
                brandName,
                dietDaysLength: currentDietItemPresentBefore.days.length,
                dietAddon: currentAddon,
              })
            );
          } else if (currentAddon.quantity !== prevAddon.quantity) {
            const quantityDiff = currentAddon.quantity - prevAddon.quantity;

            const addonInGtmFormat = convertDietAddonToGtmItem({
              brandName,
              dietDaysLength: currentDietItemPresentBefore.days.length,
              dietAddon: { ...currentAddon, quantity: quantityDiff },
            });

            if (quantityDiff < 0) {
              gtmRemovedDietItemsWithDietAddons.push(addonInGtmFormat);
            } else {
              gtmAddedDietItemsWithDietAddons.push(addonInGtmFormat);
            }
          }
        }
      });
    });
  });

  return {
    gtmRemovedDietItemsWithDietAddons,
    gtmAddedDietItemsWithDietAddons,
  };
};

export default getGtmDietItemsWithDietAddons;
