import BASKET_INITIAL_STATE from '@constants/basketInitialState';

import {
  addDietReducer,
  addItemToBasketReducer,
  removeDietReducer,
  setAdditionalMealTypesReducer,
  setRevalidateBasketReducer,
  toggleBasketDayReducer,
  toggleBasketGroupReducer,
  updateAdditionalMealTypesReducer,
  updateBasketWithCurrentDietReducer,
  updateDietReducer,
} from './basket.actions';

export const reducers = {
  clearBasket: state => {
    return {
      ...BASKET_INITIAL_STATE,
      basketMode: state.basketMode,
    };
  },
  setIsMobileBasketOpen: (state, { payload: isOpen }) => {
    state.isMobileBasketOpen = isOpen;
  },
  setBasketId: (state, { payload }) => {
    state.basketId = payload;
  },
  setActiveOrderDietIri: (state, { payload }) => {
    state.activeOrderDietIri = payload;
  },
  toggleBasketDay: toggleBasketDayReducer,
  toggleBasketGroup: toggleBasketGroupReducer,
  setRevalidateBasket: setRevalidateBasketReducer,
  addItemToBasket: addItemToBasketReducer,
  updateAdditionalMealTypes: updateAdditionalMealTypesReducer,
  setAdditionalMealTypes: setAdditionalMealTypesReducer,
  addDiet: addDietReducer,
  updateDiet: updateDietReducer,
  updateBasketWithCurrentDiet: updateBasketWithCurrentDietReducer,
  removeDiet: removeDietReducer,
  setPayStatus: (state, { payload }) => {
    state.isPayActionSuccess = payload.isPayActionSuccess;
    state.isExternalPayment = payload.isExternalPayment;
    state.isRedirectingToConfirm = true;
  },
  setBasketMode: (state, { payload }) => {
    state.basketMode = payload;
  },
  setIsValid: (state, { payload: isValid }) => {
    state.isValid = isValid;
  },
  setDiscountCode: (state, { payload }) => {
    state.useDiscountCode = payload.useDiscountCode;
    state.discountCode = payload.discountCode;
  },
  setInvoice: (state, { payload }) => {
    state.useInvoice = payload.useInvoice;
    state.invoice = payload.invoice;
  },
  setPaymentMethod: (state, { payload }) => {
    state.paymentMethod = payload;
  },
  setUseMoneyBox: (state, { payload }) => {
    state.useMoneyBox = payload;
  },
  setDietName: (state, { payload }) => {
    const dietElementIndex = state.items.dietElements.findIndex(
      dietElement => dietElement?.['@id'] === payload.existingItem
    );

    state.items.dietElements[dietElementIndex].name = payload.name;
  },
  setDietCalendarDaysInclude: (state, { payload }) => {
    const dietElementIndex = state.items.dietElements.findIndex(
      dietElement => dietElement?.['@id'] === state.activeOrderDietIri
    );

    state.items.dietElements[dietElementIndex].saturdayInclude =
      payload.saturdayInclude;
    state.items.dietElements[dietElementIndex].sundayInclude =
      payload.sundayInclude;
  },
  setStoreFromQuery: (state, { payload }) => {
    return { ...state, ...payload };
  },
  setRedirectingToConfirm: (state, { payload }) => {
    state.isRedirectingToConfirm = payload;
  },
  setBasketValidity: (state, { payload }) => {
    state.isValid = payload.isValid;
    state.errors = payload.errors;
  },
  setBlikCode: (state, { payload }) => {
    state.blik = payload;
  },
  setDietLengthMode: (state, { payload }) => {
    const dietElementIndex = state.items.dietElements.findIndex(
      dietElement => dietElement?.['@id'] === state.activeOrderDietIri
    );

    state.items.dietElements[dietElementIndex].dietLengthMode = payload;
  },
};
