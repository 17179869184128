import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.serviceTS';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import getBasketDebounceHandler from '@utils/getBasketDebounceHandler';
import showToast from '@utils/showToast';

const createBasketAddon = async ({
  data,
  resolve,
  reject,
  clearStaleRejects,
}) => {
  clearStaleRejects();

  try {
    const response = await axios.post(ENDPOINT.BASKET_ADD_ADDON, data, {
      errorHandle: false,
    });

    const uiRows = BasketService.transformResponseBasketUI(response.data);

    resolve({ ...response.data, uiRows });
  } catch (error) {
    reject(error);
  }
};

const useCreateBasketAddon = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const mutationKey = `${basketKey}AddAddon`;

  return useMutation({
    mutationFn: ({ shouldDebounce, ...data }) => {
      return getBasketDebounceHandler({
        shouldDebounce,
        mutationKey,
        mutationFn: createBasketAddon,
        data,
      });
    },
    mutationKey: [mutationKey],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    ...options,
  });
};

export default useCreateBasketAddon;
