import getConfig from 'next/config';
import { useRouter } from 'next/router';

const useLocale = () => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  const defaultLocale = publicRuntimeConfig.defaultLocale;
  const locale = router.locale || defaultLocale;

  return { defaultLocale, locale };
};

export default useLocale;
