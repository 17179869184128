import convertDishToGtmItem from '@contexts/hooks/utils/convertDishToGtmItem';
import getBasketItemsDiff from '@contexts/hooks/utils/getBasketItemsDiff';

const getGtmDishItems = ({
  brandName,
  prevDishItems,
  currentDishItems,
  prevBasketDiscountCode,
  currentBasketDiscountCode,
}) => {
  const gtmRemovedDishItems = [];
  const gtmAddedDishItems = [];

  const {
    itemsRemoved: dishItemsRemoved,
    itemsAdded: dishItemsAdded,
    prevItemsPresentBefore: prevDishItemsPresentBefore,
    currentItemsPresentBefore: currentDishItemsPresentBefore,
  } = getBasketItemsDiff(prevDishItems, currentDishItems);

  gtmRemovedDishItems.push(
    ...dishItemsRemoved.map(dishItem =>
      convertDishToGtmItem({
        brandName,
        basketDiscountCode: prevBasketDiscountCode,
        dish: dishItem,
        totalQuantityInBasketRow: dishItem.quantity,
      })
    )
  );
  gtmAddedDishItems.push(
    ...dishItemsAdded.map(dishItem =>
      convertDishToGtmItem({
        brandName,
        basketDiscountCode: currentBasketDiscountCode,
        dish: dishItem,
        totalQuantityInBasketRow: dishItem.quantity,
      })
    )
  );

  prevDishItemsPresentBefore.forEach(prevDishItem => {
    const currentDishItemPresentBefore = currentDishItemsPresentBefore.find(
      currentDishItem => currentDishItem.id === prevDishItem.id
    );

    if (currentDishItemPresentBefore.quantity !== prevDishItem.quantity) {
      const quantityDiff =
        currentDishItemPresentBefore.quantity - prevDishItem.quantity;

      const dishItemInGtmFormat = convertDishToGtmItem({
        brandName,
        basketDiscountCode: currentBasketDiscountCode,
        dish: { ...currentDishItemPresentBefore, quantity: quantityDiff },
        totalQuantityInBasketRow: currentDishItemPresentBefore.quantity,
      });

      if (quantityDiff < 0) {
        gtmRemovedDishItems.push(dishItemInGtmFormat);
      } else {
        gtmAddedDishItems.push(dishItemInGtmFormat);
      }
    }
  });

  return {
    gtmRemovedDishItems,
    gtmAddedDishItems,
  };
};

export default getGtmDishItems;
