import getConfig from 'next/config';
import { Router } from 'next/router';

import { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { isClient } from '@utils/helpers';

const PostHogWrapper = ({ children }) => {
  const {
    publicRuntimeConfig: { postHogKey = '', postHogHost = '' },
  } = getConfig();

  const cookiebot = isClient ? window.Cookiebot : undefined;
  const isCookieConsentStatisticsEnabled = () =>
    cookiebot?.consent?.statistics || false;

  useEffect(() => {
    if (!cookiebot || !postHogKey) {
      return;
    }

    posthog.init(postHogKey, {
      api_host: postHogHost || 'https://eu.i.posthog.com',
      opt_out_capturing_by_default: !isCookieConsentStatisticsEnabled(),
      loaded: posthog => {
        posthog.debug();
      },
    });

    const changePostHogConsent = () => {
      if (isCookieConsentStatisticsEnabled()) {
        posthog.opt_in_capturing();
      } else {
        posthog.opt_out_capturing();
      }
    };

    const handleRouteChange = () => posthog?.capture('$pageview');

    window.addEventListener('CookiebotOnAccept', changePostHogConsent);
    window.addEventListener('CookiebotOnDecline', changePostHogConsent);
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      window.removeEventListener('CookiebotOnAccept', changePostHogConsent);
      window.removeEventListener('CookiebotOnDecline', changePostHogConsent);
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default PostHogWrapper;
