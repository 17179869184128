import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';

const itemTypeMap = {
  [BASKET_ROWS_TYPES.DIET_ITEM]: 'dietItems',
  [BASKET_ROWS_TYPES.DISH_ITEM]: 'dishItems',
  [BASKET_ROWS_TYPES.ADDON_ITEM]: 'addonItems',
  [BASKET_ROWS_TYPES.MEAL_TYPE_ITEM]: 'mealTypeItems',
  [BASKET_ROWS_TYPES.DAY_DELIVERY_ITEM]: 'dayDeliveryItems',
  [BASKET_ROWS_TYPES.DAY_MODIFICATION]: 'dayModificationItems',
};

const initialState = Object.values(itemTypeMap).reduce(
  (acc, value) => ({ ...acc, [value]: [] }),
  {}
);

const getGroupedBasketItems = basketRows =>
  basketRows.reduce((acc, row) => {
    const itemType = itemTypeMap[row['@type']];

    return {
      ...acc,
      [itemType]: [...acc[itemType], row],
    };
  }, initialState);

export default getGroupedBasketItems;
