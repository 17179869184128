import getRoundedPrice from '../utils/getRoundedPrice';

const getCartValue = gtmItems =>
  getRoundedPrice(
    gtmItems.reduce(
      (acc, gtmItem) =>
        acc + gtmItem.price * gtmItem.quantity * gtmItem.duration,
      0
    )
  );

export default getCartValue;
