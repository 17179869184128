import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import injectHtml from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import { PersistGate } from 'redux-persist/integration/react';

import FontLink from '@components/elements/Head/FontLink';
import GlobalStyles from '@components/GlobalStyles';
import { PAYU_PAYMENT_TYPES } from '@constants/paymentTypes';
import ROUTER_URLS from '@constants/routeUrls';
import DeclarationScript from '@features/Gtm/DeclarationScript';
import {
  selectConfig,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

import AppOrCrash from './AppOrCrash';

const GoogleAuthScript = dynamic(() =>
  import('@components/elements/Head/GoogleAuthScript')
);
const GoogleOneTap = dynamic(() =>
  import('@components/elements/ExternalAuth/GoogleOneTap')
);

const GtmScript = dynamic(() => import('@features/Gtm/GtmScript'));
const HotJarScript = dynamic(() =>
  import('@components/elements/Head/HotJarScript')
);
const PayUScript = dynamic(() =>
  import('@components/elements/Head/PayUScript')
);
const PollsterTrackScript = dynamic(() =>
  import('@components/elements/Head/PollsterTrackScript')
);
const SalesManagoScript = dynamic(() =>
  import('@components/elements/Head/SalesManagoScript')
);
const IpressoPixel = dynamic(() => import('@components/elements/IpressoPixel'));

const TrustedShopsScript = dynamic(() =>
  import('@components/elements/Head/TrustedShopsScript')
);

const App = ({ Component, pageProps, store }) => {
  const router = useRouter();
  const {
    authMethods: { google },
    branding: {
      backgroundColor,
      backgroundImage,
      customCSS,
      customScripts,
      primaryColor,
      secondaryColor,
      headTags,
    },
    modules: {
      Fonts,
      GoogleTagManager,
      HotJar,
      ItemPaymentTypes = { allowedPaymentTypes: [] },
      PollsterTrack,
      SalesManago,
      Ipresso,
      TrustedShops,
    },
  } = useAppConfigSelector(selectConfig);
  const { publicRuntimeConfig } = getConfig();

  const rootVars = {
    backgroundColor,
    backgroundImage,
    primaryColor,
    secondaryColor,
    primaryFont: Fonts.clientText.font,
    secondaryFont: Fonts.clientTitle.font,
  };

  const isGoogleOneTapEnabled =
    google?.parameters?.isGoogleOneTapEnabled &&
    ![ROUTER_URLS.AUTH_LOGIN, ROUTER_URLS.AUTH_REGISTER].includes(
      router?.asPath
    );

  const isPayUEnabled = ItemPaymentTypes.allowedPaymentTypes?.some(
    allowedType =>
      Object.values(PAYU_PAYMENT_TYPES).some(
        payUType => payUType === allowedType
      )
  );

  return (
    <>
      <Head>
        {publicRuntimeConfig?.pwaEnabled === 'true' && (
          <>
            <link rel="manifest" href="/manifest.json" />
            <link rel="apple-touch-icon" href="/icon.png" />
            <meta name="theme-color" content="#ffffff" />
          </>
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {customCSS && <style id="custom-css">{customCSS}</style>}
        {customScripts && (
          <script id="custom-script" type="text/javascript">
            {customScripts}
          </script>
        )}
        {!isEmpty(headTags) && injectHtml(headTags)}
      </Head>

      <FontLink fonts={Fonts} />

      {GoogleTagManager?.enabled && <GtmScript code={GoogleTagManager.code} />}
      <DeclarationScript isNativeApp={router?.query?.isNativeApp === '1'} />
      {google?.enabled && (
        <>
          <GoogleAuthScript />
          {isGoogleOneTapEnabled && <GoogleOneTap />}
        </>
      )}
      {Ipresso?.enabled && <IpressoPixel />}
      {HotJar?.enabled && (
        <HotJarScript id={HotJar.id} version={HotJar.version} />
      )}
      {isPayUEnabled && <PayUScript />}
      {SalesManago?.enabled && (
        <SalesManagoScript
          clientId={SalesManago.clientId}
          endpoint={SalesManago.endpoint}
        />
      )}
      {PollsterTrack?.enabled && <PollsterTrackScript />}
      {TrustedShops?.enabled && (
        <TrustedShopsScript tsId={TrustedShops?.tsId} />
      )}
      <GlobalStyles rootVars={rootVars} />

      <PersistGate persistor={store.__persistor} loading={null}>
        <AppOrCrash Component={Component} pageProps={pageProps} />
      </PersistGate>
    </>
  );
};

export default App;
