import BASKET_MODES from '@constants/basketModes';

const BASKET_INITIAL_STATE = {
  activeOrderDietIri: null,
  basketId: null,
  basketMode: BASKET_MODES.STATIC,
  blik: '',
  collapsed: {},
  discountCode: { '@id': null, value: '' },
  errors: {},
  invoice: {},
  isEmpty: true,
  isExternalPayment: false,
  isMobileBasketOpen: false,
  isPayActionSuccess: false,
  isRedirectingToConfirm: false,
  isValid: false,
  items: { dietElements: [] },
  orderDetails: {
    summary: {
      diets: 0,
      days: 0,
      dishes: 0,
      addons: 0,
      firstDeliveryDay: null,
      lastDeliveryDay: null,
    },
  },
  paymentMethod: null,
  testMode: false,
  useDiscountCode: false,
  useInvoice: false,
  useMoneyBox: false,
};

export default BASKET_INITIAL_STATE;
