import { createSlice } from '@reduxjs/toolkit';

import BASKET_INITIAL_STATE from '@constants/basketInitialState';
import BASKET_MODES from '@constants/basketModes';

import { reducers } from './basket.common';

const basketNewOrderSlice = createSlice({
  name: 'basketNewOrder',
  initialState: BASKET_INITIAL_STATE,
  reducers,
});

export const selectBasket = state => state.basketNewOrder;
export const selectCurrentDiet = ({ basketNewOrder }) => {
  return (
    basketNewOrder.items.dietElements?.find(
      row => row['@id'] === basketNewOrder.activeOrderDietIri
    ) ?? {}
  );
};

export const selectActiveOrderDietIri = ({ basketNewOrder }) =>
  basketNewOrder.activeOrderDietIri;

export const selectBasketId = ({ basketNewOrder }) => basketNewOrder.basketId;

export const selectIsBasketStatic = ({ basketNewOrder }) =>
  basketNewOrder.basketMode === BASKET_MODES.STATIC;

export const selectors = {
  selectBasket,
  selectCurrentDiet,
  selectActiveOrderDietIri,
  selectBasketId,
};

export const {
  addDiet,
  addItemToBasket,
  removeDiet,
  clearBasket,
  setActiveOrderDietIri,
  setBasketId,
  setBasketMode,
  setBasketValidity,
  setBlikCode,
  setDietName,
  setDiscountCode,
  setInvoice,
  setIsValid,
  setPaymentMethod,
  setPayStatus,
  setDietLengthMode,
  setRedirectingToConfirm,
  setIsMobileBasketOpen,
  setStoreFromQuery,
  setUseMoneyBox,
  toggleBasketDay,
  toggleBasketGroup,
  updateBasketWithCurrentDiet,
  updateDiet,
} = basketNewOrderSlice.actions;

export const actions = basketNewOrderSlice.actions;

export default basketNewOrderSlice.reducer;
