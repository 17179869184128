import { createSelector } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import findLastIndex from 'lodash/findLastIndex';
import isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

const initialState = {
  isInitialStateSet: false,
  isAnimating: false,
  currentStepIndex: 0,
  currentStepSlug: null,
  isNextDisabled: true,
  isPrevDisabled: false,
  stepsCount: 0,
  steps: [], // { label: '', slug: '', isValid: false }
};

const changeTab = (state, stepIndex) => {
  state.currentStepIndex = stepIndex;
  state.currentStepSlug = state.steps[stepIndex]?.slug;
  state.isNextDisabled = !state.steps[stepIndex]?.isValid ?? true;
  state.isAnimating = true;
};

const orderTabsSlice = createSlice({
  name: 'orderTabs',
  initialState,
  reducers: {
    setIsCurrentValid: (state, { payload = true }) => {
      if (!isEmpty(state.steps)) {
        state.steps[state.currentStepIndex].isValid = payload;
        state.isNextDisabled = !payload;
      }
    },
    setTabsInitialState: (state, { payload: steps }) => {
      state.isInitialStateSet = true;
      state.currentStepIndex = 0;
      state.currentStepSlug = steps[0].slug;
      state.stepsCount = steps.length;
      state.steps = steps;
    },
    updateSteps: (state, { payload: tabs }) => {
      const newSteps = tabs.map(tab => {
        const stateStep = state.steps.find(step => step.id === tab.id);
        if (!isEmpty(stateStep)) {
          return {
            isValid: false,
            ...stateStep,
            label: tab.label,
            slug: tab.slug,
          };
        } else {
          return { isValid: false, ...tab };
        }
      });

      if (state.currentStepIndex >= newSteps.length) {
        return;
      }

      state.currentStepSlug = newSteps[state.currentStepIndex ?? 0].slug;
      state.stepsCount = newSteps.length;
      state.steps = newSteps;
    },
    changeStepAndResetValid: (state, { payload: stepIndex }) => {
      const steps = state.steps;
      const stepsBeforeStepIndex = steps.slice(0, stepIndex);
      const lastValidStepIndex = findLastIndex(stepsBeforeStepIndex, [
        'isValid',
        true,
      ]);
      const correctLastValidStepIndex =
        lastValidStepIndex >= 0 ? lastValidStepIndex : 0;

      const changeTabToIndex =
        lastValidStepIndex === stepIndex - 1
          ? stepIndex
          : correctLastValidStepIndex;

      changeTab(state, changeTabToIndex);

      const validSteps = steps.slice(0, correctLastValidStepIndex + 1);
      const invalidSteps = steps
        .slice(correctLastValidStepIndex + 1, steps.length)
        .map(step => ({ ...step, isValid: false }));

      state.steps = [...validSteps, ...invalidSteps];
    },
    changeStep: (state, { payload }) => changeTab(state, payload),
    prevStep: state => changeTab(state, state.currentStepIndex - 1),
    nextStep: state => changeTab(state, state.currentStepIndex + 1),
    changeStepAndSetBeforeValid: (state, { payload }) => {
      state.steps.slice(0, payload).map(step => {
        step.isValid = true;
      });
      changeTab(state, payload);
    },
    setIsAnimating: (state, { payload }) => {
      state.isAnimating = payload;
    },
    resetOrderTabs: () => initialState,
  },
});

// selectors

export const selectTabs = state => state.orderTabs;
export const selectIsNextDisabled = state => state.orderTabs.isNextDisabled;
export const selectOrderTabsPick = createSelector(
  [selectTabs, (_, predicate) => predicate],
  (store, predicate) => {
    if (predicate.length === 1) {
      return _pick(store, predicate)[predicate[0]]; // return only value
    }

    return _pick(store, predicate); // return object
  }
);

export const {
  changeStep,
  changeStepAndResetValid,
  changeStepAndSetBeforeValid,
  nextStep,
  prevStep,
  resetOrderTabs,
  setIsAnimating,
  setIsCurrentValid,
  setTabsInitialState,
  updateSteps,
} = orderTabsSlice.actions;

export default orderTabsSlice.reducer;
