const getBasketItemsDiff = (prevBasketItems, currentBasketItems) => ({
  itemsRemoved: prevBasketItems.filter(prevBasketItem =>
    currentBasketItems.every(
      currentBasketItem => currentBasketItem.id !== prevBasketItem.id
    )
  ),
  itemsAdded: currentBasketItems.filter(currentBasketItem =>
    prevBasketItems.every(
      prevBasketItem => prevBasketItem.id !== currentBasketItem.id
    )
  ),
  prevItemsPresentBefore: prevBasketItems.filter(prevBasketItem =>
    currentBasketItems.some(
      currentBasketItem => currentBasketItem.id === prevBasketItem.id
    )
  ),
  currentItemsPresentBefore: currentBasketItems.filter(currentBasketItem =>
    prevBasketItems.some(
      prevBasketItem => prevBasketItem.id === currentBasketItem.id
    )
  ),
});

export default getBasketItemsDiff;
