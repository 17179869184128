import getConfig from 'next/config';

import { isBefore } from 'date-fns';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { format } from '@services/Date.service';

export const sortBy = (collection, property, order = 'ASC') => {
  if (order === 'ASC') {
    return [...collection].sort((a, b) => a[property] - b[property]);
  }

  return [...collection].sort((a, b) => b[property] - a[property]);
};

export const sortByDate = (collection, property, order = 'ASC') => {
  if (order === 'ASC') {
    return [...collection].sort(
      (a, b) => new Date(a[property]) - new Date(b[property])
    );
  }

  return [...collection].sort(
    (a, b) => new Date(b[property]) - new Date(a[property])
  );
};

export const sortDates = (collection, order = 'ASC') => {
  if (order === 'ASC') {
    return [...collection].sort((a, b) => new Date(a) - new Date(b));
  }

  return [...collection].sort((a, b) => new Date(b) - new Date(a));
};

export const orderByPositionName = (array = []) =>
  orderBy(array, ['position', 'name'], ['asc', 'desc']);

export const slugify = string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
};

export const appendElementToDocumentHead = DOMElement => {
  let head = document.getElementsByTagName('head')[0];
  head.appendChild(DOMElement);
};

const checkDomain = url => {
  let urlWithProtocol = url;
  if (url.indexOf('//') === 0) {
    urlWithProtocol = location.protocol + url;
  }

  return urlWithProtocol
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};

export const checkIsUrl = url => {
  return url.includes('https://') || url.includes('http://');
};

export const checkIsUrlExternal = url => {
  if (isClient) {
    return (
      (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
      checkDomain(location.href) !== checkDomain(url)
    );
  }

  return (
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    url.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) !== null
  );
};

export const isLocalhost = () => window.location.hostname === 'localhost';

export const getInitialFormsStateByFields = ({ fields = [], data }) => {
  const valueByType = ({ type, value }) => {
    const valueByType = {
      text: '',
      customDate: null,
      checkbox: false,
      select: { label: '', value: '' },
      phone: { number: '', countryCode: '' },
    };

    if (isFalsify(value)) {
      return valueByType[type];
    } else if (type === 'customDate') {
      return format(value);
    } else {
      return value;
    }
  };

  const values = fields.reduce((acc, { key, type }) => {
    return {
      ...acc,
      [key]: valueByType({ type, value: get(data, key) }),
    };
  }, {});

  return values;
};

export const getValueOrEmpty = value => value || '';

export const sortObjectByKeys = object =>
  Object.keys(object)
    .sort()
    .reduce((acc, key) => ((acc[key] = object[key]), acc), {});

export const isChildTypeNameEqualTo = (child, name) => child.type.name === name;
export const isChildTypeDisplayNameEqualTo = (child, name) =>
  child.type.displayName === name;

export const getChildByDisplayName = (children, name) => {
  if (isEmpty(children)) {
    return null;
  }

  if (Array.isArray(children)) {
    return children.find(child => isChildTypeDisplayNameEqualTo(child, name));
  }

  if (isChildTypeDisplayNameEqualTo(children, name)) {
    return children;
  }

  return null;
};

export const checkImageExists = imageUrl => {
  return new Promise((resolve, reject) => {
    if (imageUrl !== null) {
      const img = new Image();

      img.src = imageUrl;
      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
    } else {
      reject(false);
    }
  });
};

export const getProperty = (obj, path, defaultValue) => {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce(
      (res, key) => (res !== null && res !== undefined ? res[key] : res),
      obj
    );
  return result === undefined || result === obj ? defaultValue : result;
};

export const isDate = value => {
  switch (typeof value) {
    case 'number':
      return true;
    case 'string':
      return !isNaN(Date.parse(value));
    case 'object':
      if (value instanceof Date) {
        return !isNaN(value.getTime());
      } else {
        return false;
      }
    default:
      return false;
  }
};

export const isFalsify = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'number' && (value === 0 || isNaN(value)))
  );
};

export const getIntegerIfIntegerOrFixed = (
  number,
  decimalNumber = 2,
  defaultValue = ''
) => {
  if (!Number.isFinite(number)) {
    return defaultValue;
  }

  return Number.isInteger(number) ? number : number.toFixed(decimalNumber);
};

export const getIntegerWithCutDecimals = (number, defaultValue = '') => {
  if ([null, undefined, ''].includes(number)) {
    return defaultValue;
  }

  const numStr = number.toString();
  const decimalPointIndex = numStr.indexOf('.');
  const numDigits = decimalPointIndex != -1 ? decimalPointIndex : numStr.length;

  switch (numDigits) {
    case 1:
      return parseFloat(number.toFixed(2));

    case 2:
      return parseFloat(number.toFixed(1));

    default:
      return Math.floor(number);
  }
};

export const isEmptyObject = object =>
  Object.keys(object).length === 0 && object.constructor === Object;

export const isClient = typeof window !== 'undefined';
export const isServer = typeof window === 'undefined';

const { publicRuntimeConfig } = getConfig();
export const isDev = publicRuntimeConfig.nodeEnv === 'development';
export const isProd = publicRuntimeConfig.nodeEnv === 'production';

export const getMenuForTheDayTextKey = date => {
  const dayIndex = date?.getDay() ?? -1;

  switch (dayIndex) {
    case 1:
      return '$*menu.menuForMonday';
    case 2:
      return '$*menu.menuForTuesday';
    case 3:
      return '$*menu.menuForWednesday';
    case 4:
      return '$*menu.menuForThursday';
    case 5:
      return '$*menu.menuForFriday';
    case 6:
      return '$*menu.menuForSaturday';
    case 0:
      return '$*menu.menuForSunday';
    default:
      return '$*menu.menuForDay';
  }
};

export const getDayName = date => {
  const dayIndex = date?.getDay() ?? -1;

  switch (dayIndex) {
    case 1:
      return '$*dayName.monday';
    case 2:
      return '$*dayName.tuesday';
    case 3:
      return '$*dayName.wednesday';
    case 4:
      return '$*dayName.thursday';
    case 5:
      return '$*dayName.friday';
    case 6:
      return '$*dayName.saturday';
    case 0:
      return '$*dayName.sunday';
    default:
      return '';
  }
};

export const isBeforeDeadline = deadlineDate => {
  return isBefore(new Date(), new Date(deadlineDate));
};

export const isCMSPage = pathname => {
  return (
    pathname.includes('/diets/') ||
    pathname.includes('/addons/') ||
    pathname.includes('/zones/') ||
    pathname.includes('/dishes/')
  );
};

export const log = (...args) =>
  console.log(`[${format(new Date(), 'dd.MM.yyyy HH:mm:ss.T')}]`, ...args);

export const isDietPage = pathname => {
  return pathname.includes('/diets/');
};

export const stripHtml = (string = '') => {
  const regex = /(<([^>]+)>)/gi;
  const newString = string.replace(regex, '');

  return newString;
};

export const sanitizeUrl = input => {
  if (checkIsUrlExternal(input)) {
    return '/';
  }

  return String(input).replace(/^javascript:/i, '');
};
