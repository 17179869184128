import { useTranslation } from 'next-i18next';

import { isValidPhoneNumber } from 'react-phone-number-input';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import type { AnyObject } from 'yup/lib/object';

import useAppConfig from '@hooks/useAppConfig';

interface CustomMixedSchema extends MixedSchema<any, AnyObject, any> {
  phone: (message: string) => this;
  isNotEmpty(message: string): this;
}

const passwordRegex = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const NIP_WEIGHTS = [6, 5, 7, 2, 3, 4, 5, 6, 7] as const;

const DIC_WEIGHTS = [8, 7, 6, 5, 4, 3, 2] as const;
const UNSUPPORTED_DIC_VALUES = [
  'CZ00000000',
  'CZ000000000',
  'CZ0000000000',
] as const;

export const isValidNip = (nip: string | undefined) => {
  if (!nip) {
    return true;
  }

  if (nip.length !== 10) {
    return false;
  }

  const sum = NIP_WEIGHTS.reduce((accumulator, weight, index) => {
    return accumulator + Number(nip[index]) * weight;
  }, 0);

  return sum % 11 === Number(nip[9]);
};

const isValidDic = (dic: string | undefined) => {
  if (!dic) {
    return true;
  }

  if (
    !/^CZ\d+$/.test(dic) ||
    UNSUPPORTED_DIC_VALUES.some(unsupportedValue => unsupportedValue === dic)
  ) {
    return false;
  }

  const dicDigits = dic.slice(2);

  if (dicDigits.length === 9 || dicDigits.length === 10) {
    return true;
  }

  if (dicDigits.length !== 8) {
    return false;
  }

  const sum = DIC_WEIGHTS.reduce((accumulator, weight, index) => {
    return accumulator + Number(dicDigits[index]) * weight;
  }, 0);

  return (11 - (sum % 11)) % 10 === Number(dicDigits[7]);
};

Yup.addMethod(Yup.string, 'nip', function (message: string) {
  return this.test('isValidNip', message, isValidNip);
});

Yup.addMethod(Yup.string, 'dic', function (message: string) {
  return this.test('isValidDic', message, isValidDic);
});

Yup.addMethod(Yup.string, 'password', function (message: string) {
  return this.matches(passwordRegex, message);
});

Yup.addMethod(
  Yup.string,
  'repassword',
  function (refInputName: string, message: string) {
    return this.oneOf([Yup.ref(refInputName), null], message);
  }
);

Yup.addMethod(Yup.mixed, 'phone', function (message: string) {
  return this.test(
    'invalidNumber',
    message,

    function ({ number }: { number?: string } = {}) {
      return isValidPhoneNumber(number ?? '');
    }
  );
});

Yup.addMethod(Yup.mixed, 'isNotEmpty', function (message: string) {
  return this.test(
    'requiredField',
    message,

    function ({ value }: { value?: unknown } = {}) {
      return !isEmpty(value);
    }
  );
});

export const useDefaultSchemaYup = () => {
  const { t } = useTranslation();

  const { data } = useAppConfig();

  const salesMarket = data ? data.multinational.salesMarket : '';

  const requiredError = t('$*error.field.required');

  const stringRequired = Yup.string().required(requiredError);
  const passwordRequired = stringRequired.password(
    t('$*error.field.password.invalid', { replace: { count: 8 } })
  );

  const getVatNumberValidation = () => {
    switch (salesMarket) {
      case 'pl':
        return stringRequired.nip(t('$*error.field.nip.invalid'));
      case 'cs':
        return stringRequired.dic(t('$*error.field.nip.invalid'));
      default:
        return stringRequired;
    }
  };

  return {
    stringRequired,
    numberRequired: Yup.number().required(requiredError),
    emailRequired: stringRequired.email(t('$*error.field.email.invalid')),
    passwordRequired,
    phoneValid: (Yup.mixed() as unknown as CustomMixedSchema).phone(
      t('$*error.field.phone.invalid')
    ),
    booleanRequired: Yup.boolean().required().oneOf([true], requiredError),
    isNotEmptyValid: (Yup.mixed() as unknown as CustomMixedSchema).isNotEmpty(
      requiredError
    ),
    vatNumberRequired: getVatNumberValidation(),
    dateValid: Yup.string()
      .nullable()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        t('$*error.field.date.invalid', 'Nieprawidłowa data')
      ),
  };
};

export default Yup;
