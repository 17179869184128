import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { BasketDiet } from '@tsTypes/api/BasketDiet';
import type { Address } from 'types/Address';

type Basket = {
  '@id': string;
  '@type': 'Basket';
  abandoned: boolean;
  availableBonuses: { [key: string]: unknown };
  createdAt: string;
  defaultAddress: Address;
  defaultPickUpPoint: { [key: string]: unknown };
  discountCode: null;
  id: string;
  invoiceType: string;
  notifications: unknown[];
  paymentMetadata: { [key: string]: unknown };
  paymentType: string;
  paymentTypes: string[];
  price: { [key: string]: unknown };
  rows: BasketDiet[];
  testMode: boolean;
  uiRows: { [key: string]: unknown };
  useMoneybox: boolean;
};

const useBasket = ({ basketKey }: { basketKey: string }) => {
  const queryClient = useQueryClient();

  const query = useQuery<Partial<Basket | { [key: string]: never }>>({
    queryKey: [basketKey],
    queryFn: () => queryClient.getQueryData<Basket>([basketKey]) || {},
  });

  return {
    ...query,
    data: query?.data ?? {},
  };
};

export default useBasket;
