import convertAddonToGtmItem from '@contexts/hooks/utils/convertAddonToGtmItem';
import getBasketItemsDiff from '@contexts/hooks/utils/getBasketItemsDiff';

const getGtmAddonItems = ({
  brandName,
  prevAddonItems,
  currentAddonItems,
  prevBasketDiscountCode,
  currentBasketDiscountCode,
}) => {
  const gtmRemovedAddonItems = [];
  const gtmAddedAddonItems = [];

  const {
    itemsRemoved: addonItemsRemoved,
    itemsAdded: addonItemsAdded,
    prevItemsPresentBefore: prevAddonItemsPresentBefore,
    currentItemsPresentBefore: currentAddonItemsPresentBefore,
  } = getBasketItemsDiff(prevAddonItems, currentAddonItems);

  gtmRemovedAddonItems.push(
    ...addonItemsRemoved.map(addonItem =>
      convertAddonToGtmItem({
        brandName,
        basketDiscountCode: prevBasketDiscountCode,
        addon: addonItem,
      })
    )
  );
  gtmAddedAddonItems.push(
    ...addonItemsAdded.map(addonItem =>
      convertAddonToGtmItem({
        brandName,
        basketDiscountCode: currentBasketDiscountCode,
        addon: addonItem,
      })
    )
  );

  prevAddonItemsPresentBefore.forEach(prevAddonItem => {
    const currentAddonItemPresentBefore = currentAddonItemsPresentBefore.find(
      currentAddonItem => currentAddonItem.id === prevAddonItem.id
    );

    if (currentAddonItemPresentBefore.quantity !== prevAddonItem.quantity) {
      const quantityDiff =
        currentAddonItemPresentBefore.quantity - prevAddonItem.quantity;

      const addonItemInGtmFormat = convertAddonToGtmItem({
        brandName,
        basketDiscountCode: currentBasketDiscountCode,
        addon: {
          ...currentAddonItemPresentBefore,
          quantity: quantityDiff,
        },
      });

      if (quantityDiff < 0) {
        gtmRemovedAddonItems.push(addonItemInGtmFormat);
      } else {
        gtmAddedAddonItems.push(addonItemInGtmFormat);
      }
    }
  });

  return {
    gtmRemovedAddonItems,
    gtmAddedAddonItems,
  };
};

export default getGtmAddonItems;
