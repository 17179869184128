export const MISC_PAYMENT_TYPES = {
  BANK_WIRE: 'BANK_WIRE',
  BLUE_MEDIA_BLIK: 'BLUE_MEDIA_BLIK',
  BLUE_MEDIA_CARD: 'BLUE_MEDIA_CARD',
  BLUE_MEDIA: 'BLUE_MEDIA',
  CASH: 'CASH',
  PAYNOW: 'PAYNOW',
  PAYPO: 'PAYPO',
  STRIPE_CARD: 'STRIPE_CARD',
  STRIPE_LINK: 'STRIPE_LINK',
  TPAY: 'TPAY',
} as const;

export const PAYU_PAYMENT_TYPES = {
  PAYU_BLIK: 'PAYU_BLIK',
  PAYU_CARD: 'PAYU_CARD',
  PAYU_GOOGLE_PAY: 'PAYU_GOOGLE_PAY',
  PAYU: 'PAYU',
  PAYU_PAYPO: 'PAYU_PAYPO',
} as const;

const PAYMENT_TYPES = { ...PAYU_PAYMENT_TYPES, ...MISC_PAYMENT_TYPES } as const;

export default PAYMENT_TYPES;
