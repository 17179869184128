import { useEffect } from 'react';

const useOverflowBody = (condition = false) => {
  useEffect(() => {
    if (condition) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [condition]);
};

export default useOverflowBody;
