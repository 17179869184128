import GTM_ITEM_CATEGORIES from '../constants/gtmItemCategories';
import GTM_ITEM_XML_CATEGORIES from '../constants/gtmItemXmlCategories';

const convertAddonToGtmItem = ({
  brandName,
  basketDiscountCode,
  addon: { addon, price, quantity, day },
}) => ({
  item_id: `${addon.id}`,
  item_xml_id: `${GTM_ITEM_XML_CATEGORIES.addon}_${addon.id}`,
  item_name: addon.name,
  coupon: basketDiscountCode,
  discount: price.discount,
  index: 0,
  item_brand: brandName,
  item_category: GTM_ITEM_CATEGORIES.addon,
  item_category2: '',
  item_category3: '',
  item_category4: '',
  item_category5: '',
  item_category6: false,
  item_category7: 0,
  item_category8: '',
  item_category9: '',
  item_category10: day,
  item_category11: day,
  item_category12: '',
  price: addon.clientCost,
  quantity: Math.abs(quantity),
  duration: 1,
});

export default convertAddonToGtmItem;
