import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';

const createBasketAddonRange = async data => {
  const response = await axios.post(ENDPOINT.BASKET_ADD_ADDON_RANGE, data, {
    errorHandle: false,
  });

  return {
    ...response.data,
    uiRows: BasketService.transformResponseBasketUI(response.data),
  };
};

const useCreateBasketAddonRange = ({ basketKey }) => {
  const queryClient = useQueryClient();
  const mutationKey = `${basketKey}AddAddonRange`;

  return useMutation({
    mutationFn: createBasketAddonRange,
    mutationKey: [mutationKey],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
  });
};

export default useCreateBasketAddonRange;
