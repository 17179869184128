import Script from 'next/script';

const DeclarationScript = ({ isNativeApp }) => (
  <Script
    id="declaration-event"
    src="/scripts/declaration-event.js"
    strategy="beforeInteractive"
    data-is-native-app={isNativeApp}
  />
);

export default DeclarationScript;
